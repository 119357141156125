<template>
  <div class="page submitted">
    <div class="page-section">
      <div class="section-inner">
        <div class="section-title">
          <img
            class="top-logo"
            src="@/assets/images/common/thermage_logo.png"
            alt="thermage_logo"
          />
          <p class="sub-title">
            써마지<sup>®</sup> 정품팁을 사용해주셔서 감사합니다.
          </p>
          <h1 class="main-title primary--text">
            <p>케이크 기프티콘 경품은 2월 28일에 추첨을 통해 선정된</p>
            <p>당첨자에게 입력하신 휴대폰 번호로 기프티콘이 발송되며,</p>
            <p>기존에 제공되던 스타벅스 기프티콘은 영업일 기준 3~5일 이후 발송될 예정입니다.</p>
          </h1>
        </div>

        <div class="submit-btn-wrap">
          <v-btn
            depressed
            color="primary"
            x-large
            block
            @click="$router.push('/')"
            >홈 화면으로 돌아가기</v-btn
          >
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
.submitted {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
    .section-inner .section-title {
      .main-title {
        font-size: 34px;
      }
    }
    @media (max-width: 768px) {
      div.section-inner .section-title {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
