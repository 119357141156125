<template>
  <div class="terms">
    <h4>[2025년 2월 이벤트 참여안내]</h4>
    <br />
    •이벤트 진행기간: 2025년 2월 3일 10:00시~2월 16일 17:00 <br />
    •경품발송: 이벤트 종료 후, 공정한 추첨을 통해 선정된 당첨자에게 입력하신 휴대폰 번호로 기프티콘이 발송됩니다. <br />
    <span class="leftTab">※ 경품은 시술 받으신 본인만 수령 가능하며, 타인에게 양도할 수 없습니다. <br /></span>
    <span class="leftTab">※ 해외 휴대폰 번호로는 경품 발송이 불가 하오니 국내 번호를 입력해주세요. <br /></span>
    <span class="leftTab">※ 휴대폰 번호를 잘못 입력한 경우 수정 및 재발송이 불가합니다. <br /></span>
    <span class="leftTab">※ 이벤트 기간 외에 등록된 정품 인증은 이벤트 대상에서 제외됩니다. <br /></span>
    <span class="leftTab">※ 이벤트 종료 후 당첨자 발표는 2월 28일 이후 진행될 예정입니다. <br /></span>
    <span class="leftTab">※ 경품은 품절 시 다른 상품으로 대체될 수 있습니다. <br /></span>
  </div>
</template>
  
<script>
export default {};
</script>
  
<style>
.terms li {
  list-style: disc;
}
.leftTab {
  margin-left: 20px;
}
</style>
