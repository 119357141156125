<template>
  <v-dialog v-model="dialog" max-width="400px" content-class="common-dialog" @click:outside="goBack">
    <v-card>
      <v-card-text>
        <!-- <img src="@/assets/images/default/notice_img_sample.png" alt="" /> -->
        <v-carousel :show-arrows="false">
          <v-carousel-item >
            <!-- 클릭 시 홈으로 이동하도록 임시처리 -->
            <a @click="goBack" target="_self">
              <img src="@/assets/images/default/redirectNormalEventPage.png" alt="">
            </a>
          </v-carousel-item>
          <!-- <v-carousel-item >
            <a href="https://new-thermage.com/cs/notice_detail/84" target="_self">
              <img src="@/assets/images/default/2.png" alt="">
            </a>
          </v-carousel-item> -->
          <!-- <v-carousel-item >
            <a href="https://new-thermage.com/cs/notice_detail/85" target="_self">
              <img src="@/assets/images/default/1.jpg" alt="">
            </a>
          </v-carousel-item>
          <v-carousel-item >
            <a href="https://new-thermage.com/cs/notice_detail/84" target="_self">
              <img src="@/assets/images/default/2.jpg" alt="">
            </a>
          </v-carousel-item> -->
          
        </v-carousel>
      </v-card-text>
      <v-card-actions>
        <!-- <v-checkbox v-model="blockToday" @click="checkModalToday" label="오늘 하루 보지 않기">
        </v-checkbox> -->
        <!-- 클릭 시 홈으로 이동하도록 임시처리 -->
<!--        <v-btn color="primary" depressed @click="goBack"> 닫기 </v-btn>-->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cookie from "js-cookie";
import {mapActions, mapState} from "vuex";
export default {
  data() {
    return {
      dialog: false,
      blockToday: false,
      productCode: "",
    };
  },
  methods: {
    open(productCode) {
      this.dialog = true;
      this.productCode = productCode;
      this.timer = setTimeout(() => {
        this.saveCertify(productCode);
        this.$router.push("/certify/authenticated");
      }, 10000);
    },
// main으로 강제로 보내기 위한 임시함수
    goBack() {
      this.$router.push('/certify/authenticated');
  },

    ok() {
      this.dialog = false;
    },

    checkModalToday() {
      this.setCookie("homePopup", "Y", 1);
      this.dialog = false;
    },
    setCookie(key, value, expireDays) {
      cookie.set(key, value, {
        expires: expireDays,
        path: "",
      });
    },
    ...mapActions(["saveCertify"]),
  },
};
</script>

<style lang="scss">
.v-dialog.common-dialog {
  .v-card {
    .v-card__text {
      padding: 1.2rem 1rem 1rem;

      img {
        width: 100%;
      }
    }

    .v-card__actions {
      position: relative;
      padding: 1rem 1rem 1.5rem;
      justify-content: flex-end;

      .v-input--checkbox {
        position: absolute;
        left: 20px;
      }
    }
  }
}</style>
