<template>
  <div class="page authenticated">
    <div class="page-inner">
      <div class="page-title">
        <h1 class="main-title primary--text">
          정품인증 완료를 위해 추가정보를 입력해주세요.
        </h1>
        <p class="sub-title">
          정품인증 사은품 전달을 위해 아래 정보를 입력해주세요. <br />
          써마지<sup>®</sup> 정품인증번호는 팁 박스에 부착된 바코드 번호와
          연동되어 있으며<br />
          시술 병원명이 바코드 출고 정보와 상이할 경우 참여가 불가하며 경품이
          증정되지 않을 수 있습니다.
        </p>
      </div>
      <v-form ref="form">
        <div v-if="isDev">{{ certify.productCode }}</div>
        <v-text-field
            label="성함"
            ref="refName"
            solo
            height="52"
            required
            v-model="customerData.name"
            :rules="[nameRules.required, nameRules.counter]"
        />
        <v-row>
          <v-col class="pa-0" cols="12" md="6">
            <v-text-field
                label="휴대전화번호"
                ref="refCell"
                solo
                height="52"
                required
                v-model="customerData.cell"
                type="text"
                :rules="isForeigner === 'Y' ? [] : [cellRules.required, cellRules.counter, cellRules.check]"
                @input="changeCell"
            />
          </v-col>
          <v-col class="pa-0" cols="12" md="2">
            <v-btn
                color="primary"
                x-large
                depressed
                :disabled="checkSms"
                @click="clickSendCode"
            >카카오톡 인증번호 받기</v-btn
            >
          </v-col>
          <v-col class="pa-0" cols="6" md="4">
            <v-checkbox
                required
                v-model="isForeigner"
                true-value="Y"
                false-value="N"
                label="**외국인 (Foreigner,外国人)"
                @click="clickForeigner(isForeigner)"
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <!-- 인증번호 받기 버튼 클릭 시 노출 -->
        <v-row class="row-code" v-if="isSend">
          <v-col cols="9">
            <v-text-field
                label="인증번호"
                ref="refCode"
                solo
                height="52"
                required
                type="text"
                v-model="inputCode"
                @input="changeCode"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
                color="primary"
                x-large
                depressed
                :disabled="checkedSms"
                @click="completeCertify"
            >인증완료</v-btn
            >
          </v-col>
        </v-row>
        <p class="input-guide input-bottom point-color">
          ※휴대폰번호를 정확히 입력해주세요. 입력하신 휴대폰번호로 사은품이
          발송됩니다.
        </p>

        <!-- 병원검색 -->
        <!-- <SearchHospital :customerData="customerData" /> -->
        <v-row>
          <v-col class="pa-0" cols="12" md="9">
            <v-text-field
                label="병원명"
                ref="refBelong"
                solo
                height="52"
                required
                v-model="customerData.belong"
                :rules="[hospitalRules.required]"
                @keydown.enter.prevent="clinicEnter"
            />
          </v-col>
          <v-col class="pa-0" cols="12" md="3">
            <v-btn
                class="mb-6"
                color="primary"
                x-large
                depressed
                block
                @click="clickSearch"
            >검색</v-btn
            >
          </v-col>
        </v-row>

        <v-text-field
            label="상세주소"
            ref="refAddress"
            solo
            height="52"
            required
            v-model="customerData.address"
            :rules="[addressRules.required]"
        />
        <v-select
            :items="itemsREPNum"
            label="시술샷 수 선택"
            solo
            height="52"
            required
            ref="refREPNum"
            v-model="customerData.repNum"
            :rules="[REPNumRules.required]"
        />
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="customerData.regDate"
                label="시술 일자 선택"
                append-icon="mdi-calendar"
                readonly
                solo
                v-bind="attrs"
                v-on="on"
                :rules="[REPCheckDateRules.required]"
            />
          </template>
          <!-- :min="toDate" -->
          <v-date-picker
              v-model="customerData.regDate"
              @input="menu2 = false"
          />
        </v-menu>
<!--        <Address ref="refAddress" :userData="customerData" @openSearch="openSearch" />-->
<!--        <AddressSearchDaum ref="refAddressSearch" @searchCompleteUse="searchCompleteUseHandler" />-->
<!--        <br />-->
        <div class="terms-box">
          <TermsCampaign />
        </div>
        <div class="terms-box">
          <Terms />
        </div>
        <p class="input-guide input-top">
          ※ 귀하는 개인정보 수집 및 이용에 대하여 동의를 거부하실 수 있습니다.
          다만, 동의하지 않을 경우 정품인증 이벤트에 참여하실 수 없음을
          알려드립니다.
        </p>
        <v-radio-group row v-model="customerData.agree">
          <v-radio
              key="1"
              label="위 개인정보 수집 및 이용에 동의합니다."
              value="Y"
          />
          <v-radio key="2" label="동의하지 않습니다." value="N" />
        </v-radio-group>
        <div id="recaptcha-contact" class="g-recaptcha" data-sitekey="6LegGX4qAAAAAITtfMr6aYbfkG2MpD_bbBTghsAX" data-callback="recaptcha"></div>
        <div class="bottom-wrap">
          <v-btn color="primary" x-large depressed block @click="clickSubmit"
          >확인</v-btn
          >
          <v-btn x-large depressed block @click="$router.push('/certify')"
          >취소</v-btn
          >
        </div>
      </v-form>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>

    <!-- 비번확인 -->
    <DialogHospitalsList
        ref="refDialogHospitalsList"
        @clickRow="searchClickRow"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Address from "@/components/thermage/certify/comp/Address.vue";
import AddressSearchDaum from "@/components/common/AddressSearchDaum.vue";
import DialogHospitalsList from "./DialogHospitalsList.vue";
import SearchHospital from "./comp/SearchHospital.vue";
import Terms from "@/components/thermage/common/Terms.vue";
import TermsCampaign from "@/components/thermage/common/TermsCampaign.vue";
const rulesService = require("@/utils/rules.js");
const formatService = require("@/utils/format.js");

export default {
  components: {
    Address,
    AddressSearchDaum,
    DialogHospitalsList,
    SearchHospital,
    Terms,
    TermsCampaign,
  },
  data() {
    return {
      isDev: process.env.NODE_ENV === "development",
      nameRules: rulesService.nameRules(),
      cellRules: rulesService.cellRules(),
      hospitalRules: rulesService.hospitalRules(),
      addressRules: rulesService.addressRules(),
      REPNumRules: rulesService.REPNumRules(),
      REPCheckDateRules: rulesService.REPCheckDateRules(),
      itemsREPNum: formatService.itemsREPNum(),
      isSend: false,
      checkSms: true,
      checkedSms: true,
      inputCode: "", // 인증번호(사용자입력)
      isForeigner: "N",
      customerData: {
        name: "",
        cell: "",
        belong: "", // 소속
        address: "", // 상세 주소
        hcode: "",
        repNum: "", // 시술샷수
        regDate: "", // 시술 일자
        agree: "",
        contents: "",
        hsalesMan: "", // 영업담당자
        code: "", // 인증번호(random생성)
        isAddressSearch: false,
      },
      menu2: false,
      toDate: this.$moment().format("YYYY-MM-DD"),
    };
  },
  mounted() {
    setTimeout(() =>{
      try {
        window.grecaptcha.render('recaptcha-contact', {
          sitekey: "6LegGX4qAAAAAITtfMr6aYbfkG2MpD_bbBTghsAX",
          callback: function (resp) {
            console.log("recaptcha", resp);
            // recaptchaResult = resp;
            recaptcha(resp);
          }
        });
      } catch(error) {
        console.log('error', error);
      }
    },300);

    // if (this.isDev) {
    //   this.customerData.name = "박은비";
    //   this.customerData.cell = "01053439040";
    //   this.customerData.belong = "프리딕트병원";
    //   this.customerData.address = "상세주소 1234";
    //   this.customerData.repNum = "225";
    //   this.customerData.regDate = "2022-01-01";
    //   return;
    // }
    if (
        this.certify == null ||
        this.certify === "" ||
        this.certify.productCode === ""
    ) {
      this.$router.push("/certify");
    }
  },
  methods: {
    clickCancel() {
      this.saveCertify("");
      this.$router.push("/certify");
    },
    clinicEnter(e) {
      console.log("e", e.target.value);
      this.clickSearch();
    },
    searchClickRow(e) {
      this.customerData.belong = e.hname;
      this.customerData.address = e.haddress;
      this.customerData.hcode = e.hcode;
      this.customerData.hsalesMan = e.hsalesMan;
    },
    clickSearch() {
      const searchWord = this.customerData.belong;
      console.log("e", searchWord);
      if (searchWord === "") return;
      if (searchWord.length < 1) {
        this.$helper.showTostMessage(
            "top",
            "검색어를 1자리 이상 입력해 주세요.",
            "error"
        );
        return;
      }
      this.customerData.endpoint = "certify/searchClinic";
      this.customerData.queryMethod = "POST";
      this.customerData.searchWord = searchWord;
      this.customerData.useTip = "";
      this.getDataQuery(this.customerData).then((res) => {
        if (res === undefined) return;
        console.log("res", res);
        if (res.count === 0) {
          this.$helper.showTostMessage("top", "검색결과가 없습니다.", "info");
          return;
        }
        if (res.count === 1) {
          this.searchClickRow(res.data[0]);
          return;
        }
        if (res.count > 1) {
          this.$refs.refDialogHospitalsList.open(res.data);
        }
      });
    },
    clickSubmit() {
      // 필수확인
      if (this.$refs.form.validate() === false) {
        return;
      }

      // 동의일자 선택확인
      if (
          this.customerData.agree === "" ||
          this.customerData.agree === null ||
          this.customerData.agree !== "Y"
      ) {
        this.$helper.showTostMessage(
            "top",
            "개인정보 수집 및 이용에 동의해 주세요",
            "error"
        );
        return;
      }
      if (this.isForeigner === "N") {
        if (this.customerData.code === "") {
          this.$helper.showTostMessage(
              "top",
              "인증번호 받기 후 인증번호를 입력해 주세요.",
              "info"
          );
          this.$refs.refCell.focus();
          return;
        }
        if (this.customerData.code === "") {
          this.$helper.showTostMessage(
              "top",
              "인증완료를 해주세요.",
              "info"
          );
          this.$refs.refCode.focus();
          return;
        }
        if (Number(this.inputCode) !== Number(this.customerData.code)) {
          this.$helper.showTostMessage(
              "top",
              "전달받은 인증번호를 확인해 주세요.",
              "info"
          );
          this.$refs.refCode.focus();
          return;
        }
      }
      console.log('recaptchaResult', recaptchaResult);
      if (recaptchaResult == "fail") {
        this.$helper.showTostMessage(
            "top",
            "캡차 인증을 진행해 주세요",
            "error"
        );
        return;
      }
      this.customerData.endpoint = "certify/authenticated2";
      this.customerData.queryMethod = "PUT";
      this.customerData.productCode = this.certify.productCode.toString();
      this.customerData.authType = this.certify.authType;
      this.saveDataQuery(this.customerData).then((res) => {
        console.log(res);
        if (res === undefined || res === 0) return;
        if (res === -1) {
          this.$helper.showTostMessage(
              "top",
              "3회 이상 등록한 휴대전화 번호입니다.",
              "error"
          );
          return;
        }
        this.$helper.showTostMessage(
            "top",
            "정품인증이 정상적으로 등록되었습니다.",
            "success"
        );
        recaptchaResult = "fail";
        this.$router.push("/certify/submitted2");
      });
    },
    changeCell() {
      if (this.isForeigner === "Y") {
        this.checkSms = true;
      } else {
        this.checkSms = false;
      }
    },
    changeCode() {
      console.log("changeCode", this.customerData.cell, this.me);
      this.checkedSms = true;
      if (this.inputCode.length > 3) {
        this.checkedSms = false;
      }
    },
    clickSendCode() {
      this.$refs.refCell.disabled = true; // 휴대전화번호 입력창 비활성화
      this.checkSms = true; // 인증번호 받기 버튼 비활성화
      this.checkedSms = false; // 인증번호 입력창 활성화
      // 필수
      const DT = this.customerData;
      const RF = this.$refs;
      if (
          !this.$helper.requiredCheck(
              DT.cell,
              RF.refCell,
              `휴대폰번호를 입력해주세요`
          )
      )
        return;

      if (DT.cell.length < 10) {
        return;
      }

      this.isSend = false;
      this.customerData.endpoint = "sendCertifyKakao";
      this.customerData.queryMethod = "POST";
      this.customerData.code = this.$helper.randomNumber(1000, 9999);
      this.saveDataPreLoginQuery(this.customerData).then((res) => {
        if (res === undefined) return;
        // console.log("res", res);
        console.log("customerData", this.customerData);

        this.isSend = true;
        setTimeout(() => {
          this.$refs.refCode.focus();
        }, 1000);
      });
    },
    completeCertify() {
      console.log("", this.customerData);
      // 필수
      const DT = this.customerData;
      const RF = this.$refs;
      if (
          !this.$helper.requiredCheck(
              this.inputCode,
              RF.refCode,
              `인증번호를 입력해주세요.`
          )
      )
        return;

      if (Number(this.inputCode) !== Number(this.customerData.code)) {
        this.$helper.showTostMessage(
            "top",
            "전달받은 인증번호를 확인해 주세요.",
            "info"
        );
        this.$refs.refCode.focus();
        return;
      } else {
        this.$refs.refCode.disabled = true; // 인증번호 입력창 비활성화
        this.checkedSms = true; // 인증번호 받기 버튼 비활성화
      }

      this.checkedSms = true;
    },
    clickForeigner(e) {
      if (e === 'Y') {
        this.checkSms = true; // 인증번호 받기 버튼 비활성화
        this.isSend = false; // 인증번호 입력창 비활성화
        this.checkedSms = true; // 인증번호 입력창 비활성화
      } else {
        this.checkSms = false;
        this.isSend = true;
        this.checkedSms = false;
      }
    },
    ...mapActions(["getDataQuery", "saveDataQuery", "saveDataPreLoginQuery"]),
  },
  computed: {
    ...mapState(["certify"]),
  },
};
</script>

<style lang="scss">
.authenticated {
  background: linear-gradient(#ffffff, #ebe5e1);

  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    // display: flex;
    // align-items: center;
  }

  .row {
    margin: 0;

    .col {
      padding: 0;

      .v-btn {
        min-width: calc(100% - 10px);
        padding: 0;
        margin-left: 10px;
      }
    }
  }

  .v-input--radio-group {
    margin: 40px 0 0;

    .v-radio {
      margin-right: 40px;
    }
  }

  .v-btn-toggle {
    width: 100%;
    margin-bottom: 30px;

    .v-btn {
      width: 50%;
    }
  }

  .terms-box {
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding: 12px;
    margin-bottom: 12px;
    background: #ffffff;
    min-height: 56px;
    height: 160px;
    overflow: auto;
  }

  .bottom-wrap {
    // position: absolute;
    // bottom: 1rem;
    // width: calc(100% - 2rem);
    margin: 1rem 0 1rem;

    .v-btn--block {
      display: inline-flex;
      min-width: initial !important;
      width: calc(50% - 6px);

      &:last-child {
        margin-left: 12px;
        margin-top: 0;
      }
    }
  }

  .v-input--checkbox {
    .v-label, .v-icon {
      font-size: 16px;
      font-family: "malgun gothic";
      margin-left: 30px;
    }
  }
}

@media (max-width: 768px) {
  div.authenticated {
    .v-input--radio-group {
      margin: 40px 0 0;

      .v-radio {
        margin: 0 0 10px;
      }
    }
  }
}
</style>
