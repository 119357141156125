import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400px","content-class":"common-dialog"},on:{"click:outside":_vm.goBack},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardText,[_c(VCarousel,{attrs:{"show-arrows":false}},[_c(VCarouselItem,[_c('a',{attrs:{"target":"_self"},on:{"click":_vm.goBack}},[_c('img',{attrs:{"src":require("@/assets/images/default/redirectNormalEventPage.png"),"alt":""}})])])],1)],1),_c(VCardActions)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }