<template>
  <v-app>
    <div>
      <TopNav />

      <v-main app>
        <router-view />
      </v-main>

      <FloatingButtons />
      <Footer />
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Footer from "@/components/thermage/app/Footer.vue";
import TopNav from "@/components/thermage/app/TopNav.vue";
import FloatingButtons from "@/components/thermage/app/FloatingButtons.vue";

export default {
  name: "App",
  components: {
    Footer,
    TopNav,
    FloatingButtons,
  },
  data() {
    return {
      isShow: false,
      updateDialog: false,
      mainTitle: "써마지",
    };
  },
  computed: {
    ...mapGetters(["isAuthorized"]),
    ...mapState(["me"]),
  },
  created() {
    document.title = "Thermage FLX";
  },
  methods: {
    ...mapActions(["signout"]),
  },
  metaInfo: {
    // title: '경험할수록, 써마지FLX',
    meta: [
      { name: 'description', content: '섬세하고 안전한 콜라겐 재생 눈가 및 얼굴 주름 개선' },
      { name: 'keywords', content: '써마지, Thermage' }
    ]
  },
};
</script>

<style lang="scss">
@import "assets/scss/style";
// .v-btn {
//   margin-left: 5px;
// }
.intro {
  margin: 10px;
  font-size: 15px;
}
</style>
